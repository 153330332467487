<template>
  <div class="goods">
    <div class="goods-list">
      <div class="spec">
        <div class="warp-title-home">
          <span class="warp-title-home1" @click="back"
            >积分中心</span
          >
          > <span style="color: #333333">商品详情</span>
        </div>
        <div class="goods-text-t">
          <div class="left-title">
            <div>
              <p class="g-name">{{ query.goodsName }}</p>
            </div>
            <p class="g-desc">
              {{ query.described }}
            </p>
          </div>

          <div class="right-btn">
            <div class="g-price">
              <span>{{ query.price }}</span>
              <span class="price-title">积分</span>
            </div>
            <div>
              <div class="p-points-title">
                <span>我的积分：</span>
                <span>{{ myPoints }}</span>
              </div>
              <div
                class="btn-buy"
                v-if="myPoints >= query.price"
                @click="buyNow"
              >
                立即兑换
              </div>
              <div class="btn-buy-no" v-else>积分不足</div>
            </div>
          </div>
        </div>

        <div class="goods-tabs-1" id="detailSite">
          <p class="title">商品详情</p>
          <div>
            <div id="detail2" v-if="commodityImg" class="scrollClass">
              <DetailsCommodity :commodityImg="commodityImg"></DetailsCommodity>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="app-header-sticky"
      v-if="scrollTop > swarpSite"
      :class="{ show: scrollTop > swarpSite }"
    >
      <div v-if="scrollTop > swarpSite"></div>
      <div class="container-btn">
        <div class="container-left">
          <span>{{ query.goodsName }}</span>
        </div>
        <div class="container-middle"></div>
        <div class="container-right">
          <p></p>
          <p>
            <span style="font-size: 14px; padding-right: 3px"></span>
            <span>{{ query.price }}</span>
            <span class="title">积分</span>
          </p>
          <div class="r-btn">
            <el-button
              class="buy-now"
              v-if="myPoints >= query.price"
              @click="buyNow"
              >立即兑换</el-button
            >
            <el-button class="buy-now-no" v-else>积分不足</el-button>
          </div>
        </div>
      </div>
    </div>
    <tips-dialog v-if="isShowDialog" @close="isShowDialog=false" ></tips-dialog>
  </div>
</template>

<script setup>
import { pointsMy,pointsRedeem } from "@/api/points";
import {couponReceive} from '@/api/coupon'
import { onMounted, ref } from "vue";
import useScrollTop from "@/hooks/useScrollTops";
import DetailsCommodity from "@/views/detailsGoods/components/DetailsCommodity.vue";
import { useRouter } from "vue-router";
import TipsDialog from "@/views/pointsCenter/pointsAddress/components/tipsDialog.vue";

const router = useRouter();

const scrollTop = useScrollTop();
const swarpSite = ref();
const selectTab = ref("detail2");
// 点击悬浮标签
const topsenceSwtich = (index) => {
  //document.getElementById('detailSite1').scrollIntoView()
  document.documentElement.scrollTop =
    document.getElementById(`${index}`).offsetTop - 200;
};
const loadTopSite = () => {
  setTimeout(function () {
    const detailSite = document.getElementById("detailSite");
    swarpSite.value = detailSite.offsetTop - 200;
  }, 500);
};

const commodityImg = ref();

const query = ref();
let data = localStorage.getItem("pointsDetails");
query.value = JSON.parse(data);


onMounted(() => {
  //埋点
  localStorage.setItem('productName',query.value.goodsName)
  commodityImg.value = query.value.detailPicture.split(",").map((v) => {
    return v;
  });
  getMyPoints();
  loadTopSite();
});

//我的积分
const myPoints = ref();
const getMyPoints = () => {
  pointsMy().then((res) => {
    myPoints.value = res.data;
  });
};

//立即兑换
const loading=ref(false)
const isShowDialog=ref(false)
const buyNow = () => {
  if (loading.value){
    return
  }
  loading.value=true
  if (query.value.type===0){
    router.push({
      path: "/pointsAddress",
      query: {
        goodsCode: query.value.goodsCode,
      },
    });
    return
  }
  pointsRedeem({goodsCode:query.value.goodsCode}).then(()=>{
    return  Promise.resolve()
  }).then(()=>{
    isShowDialog.value=true
  }).finally(()=>{
    loading.value=false
  })
};

//返回
const back=()=>{
  router.push({
    path: "/pointsCenter",
  });
}

</script>

<style scoped lang="less">
.warp-title-home {
  height: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin-bottom: 30px;
}

.warp-title-home1:hover {
  color: #ce1200;
  cursor: pointer;
}

.goods-text-t {
  width: 1230px;
  display: flex;
  justify-content: space-between;

  .left-title {
    width: 50%;

    .g-name {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
    }

    .g-desc {
      margin: 14px 0;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
  }

  .right-btn {
    height: 100px;
    width: 50%;
    display: flex;
    justify-content: flex-end;

    .g-price {
      margin-right: 70px;
      display: flex;
      align-items: flex-end;
      color: #ce1200;
      font-size: 36px;
      line-height: 42.19px;

      .price-title {
        font-size: 15px;
        line-height: 30px;
        margin-left: 5px;
      }
    }
  }

  .p-points-title {
    & > span:nth-child(1) {
      color: #999999;
      font-size: 14px;
    }

    & > span:nth-child(2) {
      color: #333333;
      font-size: 24px;
    }
  }

  .btn-buy {
    width: 228px;
    height: 54px;
    border-radius: 8px;
    background: #ce1200;
    color: #ffffff;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 8px;
  }

  .btn-buy-no {
    width: 228px;
    height: 54px;
    border-radius: 8px;
    background: #d9d9d9;
    color: #999999;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 8px;
  }

  .btn-buy:hover {
    background: #d62f1f;
  }
}

.goods {
  width: 1920px;
  background: #fff;
  margin: 0 auto;

  .app-header-sticky {
    width: 1920px;
    height: 64px;
    position: fixed;
    left: 50%;
    bottom: 0;
    z-index: 99;
    background: #ffffff;
    box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transform: translateX(-50%);

    &.show {
      //transform: none;
      opacity: 1;
      transition: all 1s ease;
    }

    .container-btn {
      display: flex;
      justify-content: space-around;
      background: #ffffff;
      height: 64px;
      line-height: 64px;

      .container-left {
        display: flex;
        margin-left: -46px;

        span {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          //margin-left: 15px;
          width: 277px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .container-middle {
        display: flex;
        margin-left: -630px;

        .container-middle-1 {
          cursor: pointer;
          width: 60px;
          height: 64px;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-right: 20px;

          &.active {
            color: #ce1200;
            border-bottom: 2px solid #ce1200;
          }

          &:hover {
            color: #ce1200;
            border-bottom: 2px solid #ce1200;
          }
        }
      }

      .container-right {
        display: flex;
        align-items: center;
        margin-left: -670px;

        p:nth-child(1) {
          width: 50px;
          height: 15px;
          line-height: 15px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ce1200;
        }

        p:nth-child(2) {
          padding-left: 11px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ce1200;

          .title {
            margin-left: 2px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            color: #ce1200;
          }
        }

        .r-btn {
          padding-left: 23px;
          margin-bottom: 8px;

          .buy-now {
            width: 100px;
            height: 36px;
            background: #ce1200;
            border-radius: 18px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            border-color: #ce1200ff;
          }

          .buy-now-no {
            width: 100px;
            height: 36px;
            background: #d9d9d9;
            border-radius: 18px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            border-color: #d9d9d9;
          }
        }
      }
    }
  }

  .goods-list {
    width: 1420px;
    min-height: 1000px;
    background: #fff;
    margin: 75px auto 0;

    .spec {
      flex: 1;
      padding: 30px 0px 30px 65px;

      .goods-tabs-1 {
        width: 1230px;
        margin-top: 80px;

        .title {
          color: #333333;
          font-size: 20px;
          font-weight: bold;
        }

        .el-tabs__active-bar {
          height: 0;
        }
      }
    }
  }
}
</style>
